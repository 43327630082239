// Images
import navbar_logo from '../../images/flower-white.png';
import footer_logo from '../../images/transparent-logo.png';

import { stichImageToBGElement, stichImageToDomElement } from './image-helper';

const images = [
    {
        id: 'footer_logo',
        img: footer_logo,
        bgImage: false,
    },
];

images.forEach(obj => {
    if (obj.bgImage == true) {
        stichImageToBGElement(obj.id, obj.img);
    }

    stichImageToDomElement(obj.id, obj.img);
});