class Banner extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {

        this.innerHTML = `
        <div class="banner" id="banner">
          <div class="banner__inner">
            <p>
                Lets travel safe and adhere to COVID regulations.
                <a href="https://sacoronavirus.co.za/" target="_blank">Learn more</a>
            </p>
            <button id="jsCloseButton">&times;</button>
          </div>
        </div>
      `;
    }
}

customElements.define('banner-component', Banner);