class Navbar extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.innerHTML = `
        <nav class="navbar">
           <div class="navbar__logo__wrapper">
              <a class="navbar__logo" href="./index.html">
                <span>EVESIN EDEN</span>
              </a>
            <!--
            <img
                    id="logo"
                    class="logo"
                    alt="evesineden logo"
                /> 
            -->
           </div>

            <ul class="navbar__list">
                <li class="navbar__item"><a href="./index.html">Home</a></li>
                <li class="navbar__item"><a href="./index.html#learn-more">About</a></li>
                <li class="navbar__item"><a href="./services.html">Services</a></li>
                <li class="navbar__item"><a href="./contact.html">Contact</a></li>
            </ul>

            <div class="hamburger">
                <span class="bar"></span>
                <span class="bar"></span>
                <span class="bar"></span>
            </div>
        </nav>
      `;
    }
}

customElements.define('navbar-component', Navbar);