class Footer extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.innerHTML = `
        <footer class="footer">
            <div class="footer__inner">
                <div class="footer__col">
                    <div class="footer__logo__wrapper">
                        <img
                            id="footer_logo"
                            class="logo"
                            alt="evesineden logo"
                        />
                    </div>

                    <div class="footer__qoute">
                        <h6>“Exceeding expectations is where satisfaction ends and loyalty begins”</h6>
                    </div>

                    <div class="footer__lead">
                        <p>© 2021</p>
                    </div>
                </div>

                <div class="footer__col">
                    <ul class="footer__nav__list">
                        <li><a href="./legal.html">Legal</a></li>
                        <li><a class="normal" href="./privacy.html">Privacy</a></li>
                        <li><a class="normal" href="./legal.html">Terms and Conditions</a></li>
                        <li><a class="normal" href="./coming-soon.html">Support</a></li>
                    </ul>
                </div>
                
                <div class="footer__col">
                    <ul class="footer__nav__list">
                        <li><a href="#">About</a></li>
                        <li><a class="normal" href="./services.html">Services</a></li>
                        <li><a class="normal" href="./coming-soon.html">Contact Us</a></li>
                    </ul>
                </div>
           </div>
        </footer>
      `;
    }
}

customElements.define('footer-component', Footer);