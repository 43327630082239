module.exports = {
    stichImageToDomElement: (element_id, image_path) => {
        const img = document.getElementById(element_id);
        img.src = image_path;
        img.draggable = false;
        img.style.userSelect = "none";
    },

    stichImageToBGElement: (element_id, image_path) => {
        const img = document.getElementById(element_id);
        img.style.backgroundImage = `url('${image_path}')`;
    },
};